<template>
    <div class="card-body">

        <div class="row">
            <div class="col-6">
                <el-input @change="searchEvent()" v-model="keyword" placeholder="Buscar..." clearable />
            </div>
            <div class="col-6 text-right">
                <el-button @click="createEvent()" >+ Nuevo evento</el-button>
            </div>
            <div class="col-12">
                <el-table
                    :data="events"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="start_end"
                        label="Fecha"
                        width="200">
                    </el-table-column>
                    <el-table-column
                        prop="full_name"
                        label="Nombre completo"
                        width="250">
                    </el-table-column>
                    <el-table-column
                        prop="week_days.string"
                        label="Días de la semana"
                        width="250">
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        label="Precio (€)"
                        width="100">
                        
                    </el-table-column>
                    <el-table-column
                        prop="description"
                        label="Descripción"
                        width="300">
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <el-dropdown>
                                <el-button class="el-dropdown-link" >
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-edit"><span @click="editEvent(scope.row.id)">Editar</span></el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-date"><span @click="showEvent(scope.row.id)">Fechas</span></el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-picture"><span @click="galleryEvent(scope.row.slug)">Imagen</span></el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete"><span @click="deleteEvent(scope.row.slug)">Eliminar</span></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <span v-if="!events">No hay eventos</span>

    </div>
</template>

<script>
export default {
    data() {
        return {
            events: [],
            query: {
                keyword: '',
            },
            keyword: '',
        }
    },
    created(){
        this.fetchEvents()
    },
    methods: {
        fetchEvents(){
            axios
                .get('/api/events', { params: this.query })
                .then(res => {
                    console.log(res);
                    this.events = res.data.events
                })
        },
        createEvent(){
            window.location.href = "/event-form"
        },
        editEvent(event_id){
            window.location.href = `/event-form?event_id=${event_id}`
        },
        showEvent(event_id){
            window.location.href = `/event-show?event_id=${event_id}`
        },
        deleteEvent(event_slug){
            this.$confirm('Tenga en cuenta que puede haber reservas asociadas a este evento y que los días asociados a este evento también se eliminarán', '¿Seguro que desea eliminar el evento?', {
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                    axios
                    .delete(`/api/events/${event_slug}`)
                    .then(() => {
                        this.fetchEvents()
                        this.$message({
                            type: 'success',
                            message: 'Evento eliminado correctamente'
                        });
                    })
                })
        },
        galleryEvent(event_slug){
            window.location.href = `/media-form/${event_slug}`
        },
        searchEvent(){
            this.query.keyword = this.keyword
            this.fetchEvents()
        },
    }
}
</script>

<style>

</style>