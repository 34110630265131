<template>
    <div>
        <div class="card-body">
            <el-form ref="bookingForm" :rules="rules" :model="bookingForm" label-position="top">
                <div class="row">
                    <div class="col-12">
                        <el-form-item label="Nombre y apellidos" prop="name">
                            <el-input v-model="bookingForm.name" placeholder="Nombre y apellidos"/>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-md-6">
                        <el-form-item label="Dirección de correo electrónico" prop="email">
                            <el-input v-model="bookingForm.email" placeholder="mi@email.com"/>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-md-6">
                        <el-form-item label="Confirmar correo electrónico" prop="confirm_email">
                            <el-input v-model="confirm_email" placeholder="mi@email.com"/>
                        </el-form-item>
                    </div>

                    <div class="col-12 col-md-6">
                        <el-form-item label="Teléfono" prop="phone">
                            <el-input v-model="bookingForm.phone"/>
                        </el-form-item>
                    </div>
                    <div class="col-12">
                        <el-form-item label="Políticas" prop="policies">
                            <el-checkbox-group v-model="bookingForm.policies">
                                <el-checkbox name="type">Acepto las <a href="https://dianaserena.es/condiciones-generales-de-uso" target="_blank">condiciones generales de uso</a> y <a href="https://dianaserena.es/politica-de-privacidad" target="_blank">la politica de privacidad</a> </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                </div>   
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 ml-auto">
                        <el-button @click="bookEvent()" type="primary" size="large" class="w-100">
                            <i class="fa fa-calendar-check-o fa-fw fa-btn"></i>
                            Comprar
                        </el-button>
                    </div>
                </div>
            </el-form>
            <div class="row" style="visibility: hidden;">
               <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <span ref="redsysRef" v-html="redsysForm"></span>
               </div> 
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
props: ['event', 'eventDate'],
data() {
    let validateEmail = (rule, value, callback) => {
        if (value === '') {
            return callback(new Error('El correo es obligatorio'));
        }
        setTimeout(() => {
            if (this.bookingForm.email !== '') {
            this.$refs.bookingForm.validateField('email');
        }
        callback();
        }, 1000);
        
    };
    let validateConfirmEmail = (rule, value, callback) => {
        if (value === '') {
            return callback(new Error('Debe confirmar el correo'));
        } else if (this.confirm_email !== this.bookingForm.email) {
            console.log(this.bookingForm.email);
            console.log(this.confirm_email);
            return callback(new Error('Los correos deben coincidir'));
        } else {
            return callback();
        }
    }
    return {
        bookingForm: {
            name: '',
            email: '',
            phone: '',
            policies: []
        },
        confirm_email: '',
        redsysForm: null,
        rules: {
            name: [
                { required: true, message: 'El nombre es obligatorio', trigger: 'change' },
            ],
            email: [
                { validator: validateEmail, trigger: 'blur' },
                { type: 'email', message: 'Debe introducir una dirección de correo válida', trigger: ['blur'] }
            ],
            confirm_email: [
                { validator: validateConfirmEmail, trigger: 'blur' },
                { type: 'email', message: 'Debe introducir una dirección de correo válida', trigger: ['blur'] }
            ],
            policies: [
                { type: 'array', required: true, message: 'Debe aceptar las políticas', trigger: 'change' },
            ],
        }
    }
},
created(){
    console.log(this.event);
},
methods: {
    bookEvent(){
        this.$refs['bookingForm'].validate((valid) => {
            if (!valid) {
                this.$notify({
                    title: 'Rellene los campos correctamente',
                    type: 'warning'
                });
                return false
            }
            this.payEvent()
        });
    },
    payEvent(){
        const data = { 
            event_id: this.event.id,
            eventDate_id: this.eventDate.id,
            ...this.bookingForm
        }
        data.policies = true
        axios.post('/api/booking', data)
            .then( response => {
                console.log(response);
                this.redsysForm = response.data
                this.$nextTick(() => {
                    console.log(this.$refs.redsysRef);
                    console.log('hijo 1');
                    console.log(this.$refs.redsysRef.childNodes[1]);
                    this.$refs.redsysRef.childNodes[1].submit()
                })
            })
    },
}
}
</script>

<style>

</style>