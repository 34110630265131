<template>
    <div class="card-color">
        <div class="card-body text-center">
            <i class="el-icon-success" style="font-size: 5rem; color: white;"></i>
        </div>
        <div class="card-body text-center pt-0">
            <h4 class="text-white">Pago realizado correctamente</h4>
            <h5 class="text-white">Su identificador de reserva es: {{ booking.order }}</h5>
            <p class="text-white mt-4">Revise la bandeja de entrada y spam de su correo electrónico, le debe de haber llegado la confirmación de la compra del evento. En caso de que no le haya llegado la confirmación por favor envíe un correo con la información de compra a info@dianaserena.es</p>
            <p class="mt-5">
                <a href="/calendar" class="btn btn-light" role="button" aria-pressed="true">
                    <i class="el-icon-s-home"></i> 
                    Volver al inicio
                </a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['booking'],
}
</script>

<style scoped>
.card-color{
    background-color: rgb(88, 211, 170);
}
.btn-light{
    color:rgb(88, 211, 170);
}
</style>