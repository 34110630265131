<template>
    <div>
        <div class="card-header">
            <h5>{{ eventForm.currentEvent.id === 0 ? 'Crear' : 'Editar' }} Evento</h5>
        </div>
        <div class="card-body"  
        >
            <el-form ref="eventForm" :rules="rules.eventForm" :model="eventForm" label-position="top">
                <div class="row">
                    <div class="col-6 col-md-6 col-lg-6 col-xl-6">
                        <el-form-item label="Título" prop="currentEvent.title">
                            <el-input v-model="eventForm.currentEvent.title" placeholder="Ej: Clase de Zumba"/>
                        </el-form-item>
                    </div>
                    <div class="col-6 col-md-6 col-lg-6 col-xl-6">
                        <el-form-item label="Subtítulo" prop="currentEvent.subtitle">
                            <el-input v-model="eventForm.currentEvent.subtitle" placeholder="Ej: Para expertos"/>
                        </el-form-item>
                    </div>
                    <template v-if="eventForm.currentEvent.id === 0">
                        <div class="col-3 col-md-3 col-lg-3 col-xl-3">
                            <el-form-item label="Duración" prop="eventDates.duration">
                                <el-input v-model="eventForm.eventDates.duration" placeholder="Ej: 1H 15M"/>
                            </el-form-item>
                        </div>
                        <div class="col-3 col-md-3 col-lg-3 col-xl-3">
                            <el-form-item label="Rango de fechas" prop="eventDates.dateRange">
                                <el-col>
                                    <el-date-picker
                                        v-model="eventForm.eventDates.dateRange"
                                        type="daterange"
                                        range-separator="-"
                                        start-placeholder="Fecha inicio"
                                        end-placeholder="Fecha fin">
                                    </el-date-picker>
                                </el-col>
                            </el-form-item>
                        </div>
                        <div class="col-2 col-md-2 col-lg-2 col-xl-2">
                            <el-form-item label="Hora" prop="eventDates.hour">
                                <el-col>
                                    <el-time-picker placeholder="Seleccione hora" format="HH:mm" v-model="eventForm.eventDates.hour" style="width: 100%;"></el-time-picker>
                                </el-col>
                            </el-form-item>
                        </div>
                        <div class="col-4 col-md-4 col-lg-4 col-xl-4">
                            <el-form-item label="Días de la semana" prop="currentEvent.days">
                                <el-col>
                                    <el-select v-model="eventForm.currentEvent.days" multiple placeholder="Seleccione días">
                                        <el-option
                                        v-for="item in weekDays"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </div>
                    </template>
                    <div class="col-4 col-md-4 col-lg-4 col-xl-4">
                        <el-form-item label="Typo" prop="currentEvent.type">
                            <el-col>
                                <el-select v-model="eventForm.currentEvent.type" placeholder="Seleccione tipo">
                                    <el-option
                                    v-for="item in eventTypes"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="col-6 col-md-6 col-lg-6 col-xl-6">
                            <el-form-item label="Url de evento" prop="currentEvent.url">
                                <el-col>
                                    <el-input placeholder="https://zoom.com" v-model="eventForm.currentEvent.url">
                                        <template slot="prepend">URL</template>
                                    </el-input>
                                </el-col>
                            </el-form-item>
                        </div>
                    <div class="col-3 col-md-3 col-lg-3 col-xl-3">
                        <el-form-item label="Precio (€)" prop="currentEvent.price">
                            <el-input-number v-if="is_free" v-model="eventForm.currentEvent.price" :step="1.00" disabled />
                            <el-input-number v-else v-model="eventForm.currentEvent.price" :step="1.00" />
                        </el-form-item>
                    </div>
                    <div class="col-2 col-md-2 col-lg-2 col-xl-2">
                        <el-form-item label="¿Es gratuito?">
                            <span>NO</span>
                            <el-switch
                                v-model="is_free"
                                active-color="#13ce66"
                            >
                            </el-switch>
                            <span>Si</span>
                        </el-form-item>
                    </div>
                    <div class="col-6 col-md-6 col-lg-6 col-xl-6">
                        <el-form-item label="Lugar (Zoom por defecto)" prop="currentEvent.site">
                            <el-input v-model="eventForm.currentEvent.site" placeholder="Zoom"/>
                        </el-form-item>
                    </div>
                    <div class="col-6 col-md-6 col-lg-6 col-xl-6">
                        <el-form-item label="Clase de Live Mensual">
                            <span>NO</span>
                            <el-switch
                                v-model="live_mensual"
                                active-color="#13ce66"
                            >
                            </el-switch>
                            <span>Si</span>
                            <!-- <el-checkbox v-model="live_mensual" >Opción</el-checkbox> -->
                        </el-form-item>
                    </div>
                </div>   
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                        <el-form-item label="Descripción" prop="description">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 4, maxRows: 12}"
                                placeholder="Descripción"
                                v-model="eventForm.currentEvent.description">
                            </el-input>

                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 text-right">
                        <el-button :loading="loading" @click="goBack()" type="danger">Cancelar</el-button>
                        <el-button :loading="loading" @click="sendForm()" type="primary">Guardar</el-button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
    
</template>

<script>
export default {
props: ['event'],
data() {
     const isValidHttpUrl = (rule, value, callback) => {
        let url;
        try {
            url = new URL(value);
        } catch (_) {
            callback(new Error('La url tiene que empezar por el siguiente formato: http:// o https://'));
        }
        callback()
    }
    return {
        eventForm: {
            currentEvent: {
                id: 0,
                title: '',
                subtitle: '',
                description: '',
                price: 0,
                is_free: 0,
                site: 'Zoom',
                days: [],
                url: '',
                type: null,
                live_mensual: 0,
            },
            eventDates: {
                selectDays: [],
                dateRange: '',
                hour: Date.now(),
                duration: '',
            },
        },
        live_mensual: false,
        is_free: false,
        weekDays: [
            {id: 1, name: 'Lun'},
            {id: 2, name: 'Mar'},
            {id: 3, name: 'Mx'},
            {id: 4, name: 'Jue'},
            {id: 5, name: 'Vie'},
            {id: 6, name: 'Sab'},
            {id: 0, name: 'Dom'},
        ],
        eventTypes: [
            {id: 1, name: 'Zumba Fitness'},
            {id: 2, name: 'Strong Nation'},
            {id: 3, name: 'Pilates MAT'},
            {id: 4, name: 'Master Class'},
        ],
        loading: false,
        rules: {
            eventForm: {
                currentEvent: {
                    title: [
                        { required: true, message: 'El título es obligatorio', trigger: 'change' },
                    ],
                    subtitle: [
                        { required: true, message: 'El subtítulo es obligatorio', trigger: 'blur' }
                    ],
                    days: [
                        { type: 'array', required: true, message: 'Debe seleccionar un día como mínimo', trigger: 'change' }
                    ],
                    price: [
                        { required: true, message: 'El precio es obligatorio', trigger: 'change' },
                    ],
                    description: [
                        { required: true, message: 'La descripción es obligatoria', trigger: 'change' }
                    ],
                    type: [
                        { required: true, message: 'El tipo es obligatorio', trigger: 'change' }
                    ],
                    url: [
                        { required: true, message: 'La url es obligatoria', trigger: 'change' },
                        { validator: isValidHttpUrl, trigger: 'blur' },
                    ],
                }, 
                eventDates: {
                    duration: [
                        { required: true, message: 'La duración es obligatoria', trigger: 'change' }
                    ],
                    dateRange: [
                        { type: 'array', required: true, message: 'Las fechas de inicio y fin son obligatorias', trigger: 'change' }
                    ],
                    hour: [
                        { type:'date', required: true, message: 'La hora es obligatoria', trigger: 'change' }
                    ],
                }
            }
        }
    }
},
created(){
    this.eventForm.currentEvent = this.event.id ? this.event : this.eventForm.currentEvent
    this.live_mensual = this.eventForm.currentEvent.live_mensual === 1 ? true : false
    this.is_free = this.eventForm.currentEvent.is_free === 1 ? true : false
    console.log(this.event);
},
methods: {
    sendForm(){
        console.log('entra de sendForm');
        this.$refs['eventForm'].validate((valid) => {
            console.log('entra en validación');
            if (!valid) {
                console.log('formulario no es válido');
                this.$notify({
                    title: 'Rellene los campos correctamente',
                    type: 'warning'
                });
                return false
            }
            console.log('formulario válido valido');
            this.is_free == true ? this.eventForm.currentEvent.price = 0 : '';
            this.eventForm.currentEvent.id === 0 ? this.createEvent() : this.updateEvent()  
        });
        console.log('pasa de la función de validación');
    },
    createEvent(){
        this.loading = true
        console.log('entra en creado');
        this.eventForm.eventDates.selectDays = this.eventForm.currentEvent.days
        this.eventForm.currentEvent.live_mensual = this.live_mensual
        this.eventForm.currentEvent.is_free = this.is_free
        const data = {
            event: this.eventForm.currentEvent,
            eventDates: this.eventForm.eventDates,
        }
        axios.post('/api/events', data)
            .then( response => {
                console.log(response);
                window.location.href = `/media-form/${response.data.event.slug}`
                this.loading = false
            })
            .catch(e => {
                this.$notify({
                    title: e.response.data.message,
                    type: 'error'
                });
                this.loading = false
            })
    },
    updateEvent(){
        this.loading = true
        const data = {
            event:{
                title:  this.eventForm.currentEvent.title,
                subtitle:  this.eventForm.currentEvent.subtitle,
                description:  this.eventForm.currentEvent.description,
                price:  this.eventForm.currentEvent.price,
                site:  this.eventForm.currentEvent.site,
                url: this.eventForm.currentEvent.url,
                type: this.eventForm.currentEvent.type,
                live_mensual: this.live_mensual,
                is_free: this.is_free,
            },
        }
        axios.put(`/api/events/${this.event.slug}`, data)
            .then( response => {
                console.log(response);
                window.location.href = "/event-list"
                this.loading = false
            })
    },
    goBack(){
        window.location.href='/event-list'
    },
    
}
}
</script>

<style>

</style>