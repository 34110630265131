<template>
<div>
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col-6">
                <h5 class="my-1">{{ event.title }} - {{ event.subtitle }}</h5>
            </div>
            <div class="col-6 text-right">
                <el-button @click="goBack()" type="primaty" icon="el-icon-arrow-left">Volver</el-button>
            </div>
        </div>
    </div>
    <div class="card-body border-bottom">
        <span class="text-primary">
            <i class="el-icon-info"></i> La recurrecia de días es: {{event.week_days.string}}
        </span>
    </div>

    <div class="card-body">
        <el-form ref="dateForm" :rules="rules" :model="dateForm" label-position="top">
            <div class="row align-items-end">

                <div class="col-12 col-md-12 col-lg-4 col-xl-4 mb-2 mb-lg-0">
                    <el-form-item label="Rango de fechas" prop="date" class="mb-0">
                        <el-col>
                            <el-date-picker
                                v-model="dateForm.days"
                                type="daterange"
                                range-separator="-"
                                start-placeholder="Fecha inicio"
                                end-placeholder="Fecha fin">
                            </el-date-picker>
                        </el-col>
                    </el-form-item>
                </div>
                <div class="col-6 col-md-6 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                    <el-form-item label="Hora" prop="hour" class="mb-0">
                        <el-col>
                            <el-time-picker placeholder="Seleccione hora" format="HH:mm" v-model="dateForm.hour" style="width: 100%;"></el-time-picker>
                        </el-col>
                    </el-form-item>
                </div>
                <div class="col-6 col-md-6 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                    <el-form-item label="Duración" prop="duration" class="mb-0">
                        <el-input v-model="dateForm.duration" placeholder="Ej: 1H 15M"/>
                    </el-form-item>
                </div>
                <div class="col-12 col-md-12 col-lg-2 col-xl-2 text-right mb-4 mb-lg-0">
                    <el-button size="large" @click="addEventDate()" type="success" class="w-100">+ Añadir</el-button>
                </div>
            </div>
        </el-form>
        <el-table
            :data="eventDates"
            style="width: 100%"
            :header-row-style="{textAlign: 'center'}"
        >
            <el-table-column
                prop="day"
                label="Fecha"
                width="400"
                sortable
                header-align="center"
                align="center">
            </el-table-column>
            <el-table-column
                prop="hour"
                label="Hora"
                width="180"
                header-align="center"
                align="center">
            </el-table-column>
            <el-table-column
                prop="duration"
                label="Duración"
                width="250"
                header-align="center"
                align="center">
            </el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <el-button @click="deleteEventDate(scope.row.id)" type="danger" icon="el-icon-delete" circle></el-button>
                </template>
            </el-table-column>
        </el-table>

        <span v-if="!eventDates">No hay eventos</span>

    </div>
</div>
</template>

<script>
export default {
    props: ['event'],
    data() {
        return {
            eventDates: [
                {  
                    id: 0,
                    day: '',
                    hour: '',
                    duration: '',
                    event_id: 0,
                }
            ],
            dateForm: {
                days: [],
                hour: '',
                duration: '',
                event_id: 0,
            },
            rules:{},
        }
    },
    created(){
        this.fetchEventDates()
        this.dateForm.event_id = this.event.id
    },
    methods: {
        fetchEventDates(){
            const query = {
                event_id: this.event.id,
            }
            axios
                .get('/api/event-dates', { params: query })
                .then(res => {
                    console.log(res);
                    this.eventDates = res.data.event_dates
                })
        },
        createEvent(){
            window.location.href = "/event-form"
        },
        addEventDate(){
            this.$refs['dateForm'].validate((valid) => {
            if (!valid) {
                this.$notify({
                    title: 'Rellene los campos correctamente',
                    type: 'warning'
                });
                return false
            }
            
            axios
                .post('/api/event-dates', this.dateForm)
                .then( response => {
                    console.log(response);
                    this.fetchEventDates()
                    this.dateForm = {
                        days: '',
                        hour: '',
                        duration: '',
                        event_id: this.event.id,
                    }
                })
            });
        },
        deleteEventDate(event_date_id){

            this.$confirm('Tenga en cuenta que puede haber reservas asociadas a este día', '¿Seguro que desea eliminar el día?', {
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                    axios
                    .delete(`/api/event-dates/${event_date_id}`)
                    .then(() => {
                        this.fetchEventDates()
                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    })
                })
            
        },
        goBack(){
            window.location.href='/event-list'
        },
    }
}
</script>

<style>

</style>