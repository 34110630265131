<template>
<div class="card-body">
    
    <div class="row">

        <el-tabs
            v-model="eventType"
            type="card"
            class="demo-tabs"
            @tab-click="fetchEvents()"
        >
            <el-tab-pane label="Zumba Fitness" name="1" />
            <el-tab-pane label="Strong Nation" name="2" />
            <el-tab-pane label="Pilates MAT" name="3" />
            <el-tab-pane label="Master Class" name="4" />
        </el-tabs>

    </div>

    <div class="row">
        <div class="col-12 col-md-12 col-lg-8 col-xl-8">
            <FullCalendar ref="fullCalendar" class="demo-app-calendar" :options="calendarOptions">
                <template v-slot:eventContent="arg">
                    <!-- <b>{{ arg.timeText }}</b> -->
                    <el-tooltip v-if="arg.event.extendedProps.live_mensual" class="item" effect="dark" :content="'Live Mensual  '+arg.event.title" placement="top" style="overflow-x:hidden">
                        <el-tag type="danger" size="mini">
                            <img src="/img/live.svg" class="icon-live"> 
                            {{ arg.event.title }}
                        </el-tag>
                    </el-tooltip>
                    <el-tooltip v-else class="item" effect="dark" :content="arg.event.title" placement="top" style="overflow-x:hidden">
                        <el-tag type="info" size="mini">
                            {{ arg.event.title }}</i>
                        </el-tag>
                    </el-tooltip>
                </template>
            </FullCalendar>
        </div>
        <div class="col-12 col-md-12 col-lg-4 col-xl-4">
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item v-if="eventSelected" name="2">
                    <!-- <template slot="title">
                        {{eventSelected.title}}
                    </template> -->
                    <a :href="getTypeUrl(eventSelected.extendedProps)" style="text-decoration: none;">
                        <el-card shadow="hover" class="mb-3 mt-2">
                            <div class="row pb-1">
                                <div class="col-9">
                                    <b>{{eventSelected.title}}</b>
                                </div>
                                <div class="col-3 text-right">
                                    {{ eventSelected.extendedProps.duration_time }}
                                </div>
                            </div>
                            <div class="row pb-1">
                                <div class="col-6">
                                    {{ eventSelected.extendedProps.day }} - {{ eventSelected.extendedProps.hour }}
                                </div>
                                <div class="col-6 text-right">
                                    {{ eventSelected.extendedProps.price }} €
                                </div>
                            </div>
                            <div class="row pb-1">
                                <div class="col-12">
                                    {{ eventSelected.extendedProps.description.substring(0, 35) }}...
                                </div>
                            </div>
                            <div class="row pb-2" v-if="eventSelected.extendedProps.live_mensual">
                                <div class="col-12">
                                    <el-tag type="danger" size="mini"> 
                                        <img src="/img/live.svg" class="icon-live"> 
                                        Live <b>Mensual</b>
                                    </el-tag>
                                </div>
                            </div>
                            <div class="row pt-1">
                                <div class="col-12">
                                    <el-button type="primary" plain class="w-100" :href="`/eventos/${eventSelected.extendedProps.event_slug}/${eventSelected.extendedProps.day}/${eventSelected.extendedProps.hour}`">
                                        + información
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </a>
                </el-collapse-item>
                <el-collapse-item title="Próximos eventos" name="1">
                    <a :href="getTypeUrl(event)" v-for="event in currentEvents" :key="event.id" style="text-decoration: none;">
                        <el-card shadow="hover" class="mb-3 mt-2">
                            <div class="row pb-1">
                                <div class="col-9">
                                    <b>{{event.title}}</b>
                                </div>
                                <div class="col-3 text-right">
                                    {{ event.duration_time }}
                                </div>
                            </div>
                            <div class="row pb-1">
                                <div class="col-6">
                                    {{ event.day }} - {{ event.hour }}
                                </div>
                                <div class="col-6 text-right">
                                    {{ event.price }} €
                                </div>
                            </div>
                            <div class="row pb-1">
                                <div class="col-12">
                                    {{ event.description.substring(0, 35) }}...
                                </div>
                            </div>
                            <div class="row pb-2" v-if="event.live_mensual">
                                <div  class="col-12">
                                    <el-tag type="danger" size="mini"> 
                                        <img src="/img/live.svg" class="icon-live"> 
                                        Live <b>Mensual</b>
                                    </el-tag>
                                </div>
                            </div>
                            <div class="row pt-1">
                                <div class="col-12">
                                    <el-button type="primary" plain class="w-100" :href="`/eventos/${event.event_slug}/${event.day}/${event.hour}`">
                                        + información
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </a>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es';

export default {
    props: ['eventTypeSlug'],
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },

    data: function() {
        return {
            eventType: '1',
            calendarOptions: {
                customButtons: {
                    prevMonth: {
                        icon: 'fc-icon fc-icon-chevron-left',
                        click: () => {
                        const calendarApi = this.$refs.fullCalendar.getApi();
                        this.calendarOptions.events = [];
                        calendarApi.prev();
                        this.dateMonth = calendarApi.getDate()
                        this.fetchEvents();
                        },
                    },
                    nextMonth: {
                        icon: 'fc-icon fc-icon-chevron-right',
                        click: () => {
                        const calendarApi = this.$refs.fullCalendar.getApi();
                        this.calendarOptions.events = [];
                        calendarApi.next();
                        this.dateMonth = calendarApi.getDate()
                        this.fetchEvents();
                        },
                    },
                    dateNow: {
                        text: 'Hoy',
                        click: () => {
                        const calendarApi = this.$refs.fullCalendar.getApi();
                        this.calendarOptions.events = [];
                        calendarApi.today();
                        this.dateMonth = calendarApi.getDate()
                        this.fetchEvents();
                        },
                    },
                },
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                headerToolbar: {
                    left: "prevMonth,nextMonth dateNow",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                },
                initialView: "dayGridMonth",
                events: [], // alternatively, use the `events` setting to fetch from a feed
                editable: true,
                locale: esLocale,
                firstDay: 1,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents
                /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
            },
            currentEvents: [],
            dateMonth: 0,
            eventSelected: null,
            activeName: '1',
        };
    },
    created(){
        //this.eventType = this.getEventType();
        console.log(this.eventTypeSlug);
        this.fetchEvents()
    },

    methods: {
        handleWeekendsToggle() {
            this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
        },

        handleEventClick(clickInfo) {
            this.eventSelected = clickInfo.event
            this.activeName = '2'
        },

        handleEvents(events) {
            /* this.currentEvents = events.slice(0,5); */
            this.eventSelected = null
            this.activeName = '1'
        },

        fetchEvents(){
            const query = {
                calendar_date: this.dateMonth,
                event_type: this.eventType,
            }
            axios
                .get('/api/event-dates/calendar', { params: query })
                .then( response => {
                    this.calendarOptions.events = response.data.event_dates
                    console.log(this.calendarOptions.events);
                    this.currentEvents = response.data.coming_dates
                })
        },
        goToEvent(slug, event_date){
            console.log(slug);
            console.log(event_date);
            const options = {
                method: 'GET',
                
                redirect: 'follow'
            };
            fetch(slug+'/'+event_date, options)
            .then(response => {
                console.log(response);
            })
            .catch(function(err) {
                console.info(err + " url: " + slug);
            });
        },
        getEventType(){
            if (this.eventTypeSlug === 'clases-y-eventos') return 1
            if (this.eventTypeSlug === 'strong-nation') return 2
            if (this.eventTypeSlug === 'zumba-fitness') return 3
            return null
        },
        getTypeUrl(event){
            if (event.price <= 0 && event.is_free ) return event.destination
            return `/eventos/${event.event_slug}/${event.day}/${event.hour}`
        }
    }
};
</script>

<style lang="css" scoped>
h2 {
  
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.el-tag.el-tag--danger {
    background-color: rgba(180, 7, 100,.1);
    border-color: rgba(180, 7, 100,.2);
    color: #e5097f;
}

.icon-live{
    margin-top:-2px;
    height:14px;
}
</style>
