
/*
 |--------------------------------------------------------------------------
 | Laravel Spark Bootstrap
 |--------------------------------------------------------------------------
 |
 | First, we will load all of the "core" dependencies for Spark which are
 | libraries such as Vue and jQuery. This also loads the Spark helpers
 | for things such as HTTP calls, forms, and form validation errors.
 |
 | Next, we'll create the root Vue application for Spark. This will start
 | the entire application and attach it to the DOM. Of course, you may
 | customize this script as you desire and load your own components.
 |
 */

/*require('spark-bootstrap');

require('./bootstrap');

var app = new Vue({
    mixins: [require('spark')]
});*/

require('spark-bootstrap');

require('./components/bootstrap');

import Vue from "vue";
import locale from 'element-ui/lib/locale/lang/es';
import Element from 'element-ui';

window.EventBus = new Vue({});
window.Vue = require('vue');
window.axios = require('axios');
Vue.use(Element, { size: 'large', locale});
/*let token = document.querySelector('#token').dataset.token;
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;*/

Spark.forms.register = {
    company: ''
};

import InitialForm from './components/InitialForm/InitialForm.vue';
import UserData from './components/UserData/UserData.vue';
import UserActivity from './components/UserData/UserActivity.vue';
import UserHealthPart1 from './components/UserData/UserHealthPart1.vue';
import UserHealthPart2 from './components/UserData/UserHealthPart2.vue';
import UserSport from './components/UserData/UserSport.vue';
import UserNutrition from './components/UserData/UserNutrition.vue';
import UserConditions from './components/UserData/UserConditions.vue';
import EventList from './components/events/EventList.vue'
import EventForm from './components/events/EventForm.vue'
import EventShow from './components/events/EventShow.vue'
import Calendar from './components/front/calendar/Calendar.vue'
import FrontEventShow from './components/front/event/EventShow.vue'
import Ko from './components/front/redsys/Ko.vue'
import Ok from './components/front/redsys/Ok.vue'
import MediaForm from './components/events/MediaForm.vue'




Vue.component('initial-form', InitialForm);
Vue.component('user-data', UserData);
Vue.component('user-activity', UserActivity);
Vue.component('user-health-part1', UserHealthPart1);
Vue.component('user-health-part2', UserHealthPart2);
Vue.component('user-sport', UserSport);
Vue.component('user-nutrition', UserNutrition);
Vue.component('user-conditions', UserConditions);
Vue.component('event-list', EventList);
Vue.component('event-form', EventForm);
Vue.component('event-show', EventShow);
Vue.component('calendar', Calendar);
Vue.component('front-event-show', FrontEventShow);
Vue.component('ko', Ko);
Vue.component('ok', Ok);
Vue.component('media-form', MediaForm);

Vue.component('home', {
    components: {
      InitialForm,
      UserData,
      UserActivity,
      UserHealthPart1,
      UserHealthPart2,
      UserSport,
      UserNutrition,
      UserConditions,
      EventList,
      EventForm,
      EventShow,
      Calendar,
      FrontEventShow,
      MediaForm,
    },

    props: ['user'],

    mounted() {
        //
    }
});

const app = new Vue({
    mixins: [require('spark')]
});