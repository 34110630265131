<template>
    <div class="card-body">
        <h2>Imagen del evento</h2>
        <el-row>
            <!-- Photo Gallery [797 x 531 px] -->
            <el-upload
                    :action="getUrl()"
                    :headers="headerInfo"
                    list-type="picture-card"
                    :file-list="fileList"
                    :on-success="getPhotosWithTime"
                    :on-error="getError"
                    :auto-upload="true">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                    <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.thumbnail" alt=""
                    >
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span>
                            {{ file.order_gallery }}
                        </span>
                    </span>
                </div>
            </el-upload>
        </el-row>
        <div class="row pt-3">
            <div class="col-12">
                <a href="/event-list" class="btn btn-info px-5">
                    <i class="fa fa-floppy-o"></i>
                    Finalizar
                </a>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: ['event'],
        data() {
            return {
                disabled: false,
                headerInfo: {
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                    'X-CSRF-TOKEN': '',
                },
                fileList: [],
            };
        },
        methods: {

            getUrl(){
                //esta funcion monta la url a la que las fotos van a ser mandadas
                return `/api/media/upload-media?event_id=${this.event.id}`
            },

            getPhotos(){
                //esta funcion recoge las fotos ya subidas
                axios.get(`/api/events/${this.event.slug}/photos`)
                    .then( res => {
                        this.fileList=res.data.data;
                    })
                    .catch(e => {
                        console.log(e.response);
                    })
            },

            getError(result, file, filelist){
                //esta funcion muestra el codigo de error
                alert('error')
            },

            getPhotosWithTime(result, file, filelist){
                //esta funcion existe para recargar todas las fotos subidas y darle tiempo a la animacion que no se vea fea
                this.$notify({
                    title: 'Añadido satisfactoriamente',
                    message: '',
                    type: 'success'
                });
                this.fileList=[];
                setTimeout(this.getPhotos, 1000);
            },
            handleRemove(file) {
                //esta funcion elimina la foto
                let id = file.id;
                axios.get(`/api/media/${id}`)
                    .then( res => {
                        this.getPhotos()
                        this.$notify({
                            title: 'Eliminado satisfactoriamente',
                            message: '',
                            type: 'success'
                        });
                    })
                    .catch(e => {
                        console.log(e.response);
                    })
            },
        },

        created(){
            this.headerInfo['X-CSRF-TOKEN'] = document.querySelector('#token').dataset.token
            this.getPhotos();
        },
    }
</script>

<style scoped>

</style>
