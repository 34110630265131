<template>
    <div>
        
        <div class="card-header">
            <a href="#show-event" @click="goBack()" class="btn btn-sm btn-outline-info"> <i class="el-icon-arrow-left"></i> Volver</a>
        </div>
        <div class="card-body poster">
            <img v-if="event.img" :src="event.img">
        </div>

        <div class="card-body border-bottom">
            <div class="row">
                <div class="col-12 col-md-6">
                    <el-tag type="danger" size="mini" v-if="event.live_mensual"> 
                        <img src="/img/live.svg" class="icon-live"> 
                        Live <b>Mensual</b>
                    </el-tag>
                    <h2 class="mt-3 text-dark"><b>{{event.title}}</b></h2>
                    <h5><b>{{event.subtitle}}</b></h5>
                    <p class="text-muted">{{formateDate()}}</p>
                    <template v-if="!reservation">
                        <div class="d-flex flex-row">
                            <div class="border px-4 py-3">
                                {{eventDate.duration}}
                            </div>
                            <div class="border px-4 py-3">
                                {{event.price}}€
                            </div>
                            <div class="border px-4 py-3">
                                {{event.site}}
                            </div>
                        </div>
                    </template>
                </div>
                <template v-if="!reservation">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 ml-auto pt-4 pt-md-0">
                        <a class="btn btn-primary w-100" href="#booking-form" @click="changeReservation()">
                            <i class="fa fa-calendar-check-o fa-fw fa-btn"></i>
                            Comprar ahora
                        </a>
                    </div>
                </template>
            </div>
        </div>
        <template v-if="!reservation">
            <div class="card-body border-bottom">
                <h5 class="text-dark"><b>Descripción</b></h5>
                <p class="text-justify mb-1">{{event.description}}</p>
            </div>
            <div class="card-body">
                <h5 class="text-dark"><b>Política de cancelación</b></h5>
                <p class="mb-1">Para poder cancelar la compra se ha de notificar la cancelación 24h antes del evento. </p>
                <p class="mb-1">Puede solicitar su cancelación poniéndose en contacto a través del correo info@dianaserena.com</p>
            </div>
        </template>
        <template v-else id="booking-form">
            <booking-form 
                :event="event"
                :event-date="eventDate"
            />
        </template>

    </div>
</template>

<script>
import BookingForm from '../redsys/BookingFrom.vue'

export default {
props: ['event', 'eventDate'],
components: {
    BookingForm,
},
data(){
    return{
        reservation: false,
    }
},
created(){
    this.reservation = false
},
methods: {
    formateDate(){
        // Creamos array con los meses del año
        const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        // Creamos array con los días de la semana
        const dias_semana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        // Creamos el objeto fecha instanciándolo con la clase Date
        const fecha = new Date(this.eventDate.date);
        // Construimos el formato de salida
        return dias_semana[fecha.getDay()] + ', ' + fecha.getDate() + 
        ' de ' + meses[fecha.getMonth()] + 
        ' de ' + fecha.getUTCFullYear() + 
        ' a las ' + fecha.getHours() + ':' + (String(fecha.getMinutes()).length === 1 ?  fecha.getMinutes() +'0': fecha.getMinutes())
    }, 
    changeReservation(){
        this.reservation = !this.reservation;
    },
    goBack(){
        if (!this.reservation) window.location.href='/calendar'
        else
            this.changeReservation()
    },
}
}
</script>

<style scoped>

.poster{
    height:60vh;
}

.poster img{
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    height:100%;
    width:100%;
}

.el-tag.el-tag--danger {
    background-color: rgba(180, 7, 100,.1);
    border-color: rgba(180, 7, 100,.2);
    color: #e5097f;
}

.icon-live{
    margin-top:-2px;
    height:14px;
}
</style>